import React, { useState } from 'react';
import './Custody.css';
import { collection, addDoc } from 'firebase/firestore';
import { firestore } from './firebase';
import coldStorageImage from './custody-image/Coldstorage.png';
import UIImage from './custody-image/UI.png'
import Selection from './custody-image/Selection.png'

function Custody() {

  const [email, setEmail] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit action
    await addUserToWaitlist(email);
    setEmail('');
  }

  const addUserToWaitlist = async (email) => {
    if (email.includes('@')) {
      try {
        // Add a new document with a generated id to the "waitlist" collection
        const docRef = await addDoc(collection(firestore, 'waitlist'), {
          email: email, // The email field with the email value
          timestamp: new Date() // Optionally add a timestamp
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    } else {
      console.error("Invalid email address: ", email);
    }
  };


  return (
    <div className="App">

      <main className="App-main">
        {/* Hero section */}
        <section className="App-hero" id="signup-form">
          <h1>Introducing Rivendell</h1>
          <p>The premier marketplace for buying, selling, and managing NFTs.</p>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Enter your email"
              className="App-input"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button type="submit" className="App-waitlist-button button-rounded">
              Join the Waitlist
            </button>
          </form>
        </section>

        {/* Features section */}
        <section className="App-features">
          <div className="feature"> 
            <img src={coldStorageImage} alt="Secure Transactions" />
            <h2>Security at its Core</h2>
            <p>Employing cold storage solutions to safeguard your assets</p>
          </div> 
          <div className="feature">
            <img src={Selection} alt="Selection" />
            <h2>Widest Selection</h2>
            <p>Access a diverse collection of NFTs across different chains</p>
          </div>
          <div className="feature">
            <img src={UIImage} alt="UI Image" />
            <h2>Easy-To-Use</h2>
            <p>No more downloading wallet remembering passcodes</p>
          </div>
        </section>
        
        {/* Call to action section */}
        <section className="App-cta">
          <h2>The future of digital art and luxury is around the corner</h2>
          <a href="#signup-form" className="App-signup-button">Sign up now</a>
        </section>
      </main>

      {/* Footer */}
      <footer className="App-footer">
        {/* Footer content as provided in the previous example */}
      </footer>
    </div>
  );
}

export default Custody;