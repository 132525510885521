// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDSU_NQAc6KPGe_Ss5YakZzK1FCeVDkKjM",
    authDomain: "rivendell-7195a.firebaseapp.com",
    projectId: "rivendell-7195a",
    storageBucket: "rivendell-7195a.appspot.com",
    messagingSenderId: "46025587134",
    appId: "1:46025587134:web:084ecb45e8894ab33be1ef",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const firestore = getFirestore(app);